import { ReactNode, useCallback, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  FilterIcon,
  TableColumns,
  Tag,
  Text,
  Tooltip,
  Select,
  Skeleton,
  Pagination,
  PlusIcon,
  SettingIcon,
  TableColumnsColumnProps,
  TrashIcon,
  InputSearch,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import {
  formatDate,
  trimString,
  downloadFile,
  getFileNameAndExtension,
  useParamsState,
  useCookieState,
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
} from '@/helpers'
import { useQuotationRequestList, useDeleteQuotationRequest } from '@/services'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'
import { QuotationRequestProps, QuotationRequestStatusEnum } from '@/types'

import {
  QuotationRequestDrawerFilter,
  QuotationRequestDrawerForm,
} from './components'

import { TagMoscow } from '../../../profile/components'

import type { QuotationRequestListProps } from './list.type'

import styles from './list.module.css'
import { Link } from 'react-router-dom'

export const QuotationRequestList = ({
  buyerProfile,
}: QuotationRequestListProps) => {
  const { t } = useTranslation('screenQuotationRequestList')
  const { t: tEnums } = useTranslation('enums')

  const { isSuperUser } = useAuth()

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [quotationRequestUuid, setQuotationRequestUuid] = useState('')

  const [totalPages, setTotalPages] = useState(1)

  const onHandleEditRegister = (uuid: string) => {
    setQuotationRequestUuid(uuid)
    setIsOpenDrawerForm(true)
  }

  const onHandleOpenDrawerForm = (isOpen: boolean) => {
    setIsOpenDrawerForm(isOpen)

    if (!isOpen) {
      setQuotationRequestUuid('')
    }
  }

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/quotation-request-list-columns',
    [
      'reference',
      'buyerProfile.name',
      'buyerProfile.companyName',
      'buyerProfile.email',
      'buyerProfile.phone',
      'status',
      'buyerProfile.moscowClassification',
      'createdAt',
      'creatorUser.name',
      'buyerProfile.keyAccountManager.email',
      'items',
      'quotations',
      'origin',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    moscow: '',
    createdAtBefore: '',
    createdAtAfter: '',
    rootCategory: '',
  })

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) => !!filter[key] && key !== 'page' && key !== 'limit',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const { data, isLoading, refetch } = useQuotationRequestList(
    buyerProfile ? { ...filter, buyerProfile } : filter,
  )

  const { isPending, mutateAsync: mutateDelete } = useDeleteQuotationRequest()

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('quotationRequestTableDeleteConfirmTitle'),
      t('quotationRequestTableDeleteConfirmMessage'),
      t('quotationRequestTableDeleteConfirmButtonDelete'),
      t('quotationRequestTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('quotationRequestTableDeleteSuccessTitle'),
          t('quotationRequestTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('quotationRequestTableDeleteErrorTitle'),
          t('quotationRequestTableDeleteErrorMessage'),
        )
      }
    }
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  const stateObject: Record<QuotationRequestStatusEnum, ReactNode> = {
    approved_for_quotation: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_request_status_approved_for_quotation')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('quotation_request_status_completed')}
      </Tag>
    ),
    disqualified: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_disqualified')}
      </Tag>
    ),
    expired: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_expired')}
      </Tag>
    ),
    invalid: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_invalid')}
      </Tag>
    ),
    processed: (
      <Tag variant="primary" model="light">
        {tEnums('quotation_request_status_processed')}
      </Tag>
    ),
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_request_status_pending')}
      </Tag>
    ),
  }

  const getContentProduct = useCallback(
    (
      items: QuotationRequestProps['items'],
      file: QuotationRequestProps['file'],
      index: number,
    ) => {
      if (items?.length > 0) {
        const concatItems = items
          ?.map(
            (item) =>
              item?.catalogProduct?.attributes?.title || item?.product?.title,
          )
          .join(', ')

        return (
          <Tooltip
            text={concatItems}
            widthText="18.75rem"
            orientation={index >= 8 ? 'top' : 'bottom'}
          >
            {trimString(concatItems, 52, '...')}
          </Tooltip>
        )
      }

      const handleDownloadFile = async () => {
        if (!file) return

        const { fileName, extension } = getFileNameAndExtension(file || '')

        downloadFile(file, `${fileName}.${extension}`)
      }

      return (
        <Text className={styles.link} onClick={handleDownloadFile}>
          {t('quotationRequestTableDownloadFile')}
        </Text>
      )
    },
    [data],
  )

  const columns: TableColumnsColumnProps<QuotationRequestProps>[] = [
    {
      title: t('quotationRequestTableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ value }) => `#${value}`,
    },
    {
      title: t('quotationRequestTableBuyerName'),
      key: 'buyerProfile.usersNames',
      dataKey: 'buyerProfile.usersNames',
      width: '18rem',
      render: ({ record }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {record?.buyerProfile?.usersNames
            ? record?.buyerProfile?.usersNames?.join(', ')
            : '-'}
        </Link>
      ),
    },
    {
      title: t('quotationRequestTableBusinessName'),
      key: 'buyerProfile.companyName',
      dataKey: 'buyerProfile.companyName',
      width: '18rem',
      render: ({ record, value }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {value}
        </Link>
      ),
    },
    {
      title: t('quotationRequestTableEmail'),
      key: 'buyerProfile.email',
      dataKey: 'buyerProfile.email',
      width: '22rem',
      render: ({ record, value }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {value}
        </Link>
      ),
    },
    {
      title: t('quotationRequestTablePhone'),
      key: 'buyerProfile.phone',
      dataKey: 'buyerProfile.phone',
    },
    {
      title: t('quotationRequestTableState'),
      key: 'status',
      dataKey: 'status',
      render: ({ record }) =>
        stateObject[
          (record?.status || 'pending') as QuotationRequestStatusEnum
        ] || '-',
      width: '15rem',
    },
    {
      title: t('quotationRequestTableMoscow'),
      key: 'buyerProfile.moscowClassification',
      dataKey: 'buyerProfile.moscowClassification',
      render: ({ record }) => (
        <TagMoscow
          moscowClassification={record?.buyerProfile?.moscowClassification}
        />
      ),
    },
    {
      title: t('quotationRequestTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : ''),
    },
    {
      title: t('quotationRequestTableCreatorName'),
      key: 'creatorUser.email',
      dataKey: 'creatorUser.email',
      width: '16rem',
    },
    {
      title: t('quotationRequestTableSellerName'),
      key: 'buyerProfile.keyAccountManager.email',
      dataKey: 'buyerProfile.keyAccountManager.email',
      width: '16rem',
    },
    {
      title: t('quotationRequestTableOrigin'),
      key: 'origin',
      dataKey: 'origin',
      width: '16rem',
      render: ({ value }) => tEnums(`quotation_request_origin_${value}`),
    },
    {
      title: t('quotationRequestTableProducts'),
      key: 'items',
      dataKey: 'items',
      width: '20rem',
      render: ({ record, index }) =>
        getContentProduct(record.items, record.file, index),
    },
    {
      title: t('quotationRequestTableQuotations'),
      key: 'quotations',
      dataKey: 'quotations',
      width: '15rem',
      render: ({ record }) =>
        record?.quotations && record?.quotations?.length > 0 ? (
          <div className={styles['column-quotation']}>
            {record?.quotations?.map((quotation) => (
              <Link
                key={quotation?.uuid}
                to={`${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(
                  quotation?.uuid,
                )}&search=${quotation.reference}`}
                className={styles.link}
                target="_blank"
              >
                {`#${quotation?.reference}`}
              </Link>
            ))}
          </div>
        ) : (
          <Text>-</Text>
        ),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('quotationRequestTableFilterButton')}
            {getContentTotalFiltered()}
          </Button>
        </div>

        <div className={styles['filter-group']}>
          <Button onClick={() => onHandleOpenDrawerForm(true)}>
            <PlusIcon />
            {t('quotationRequestTableNewRFQButton')}
          </Button>

          <DropDown.Root>
            <DropDown.ButtonTrigger
              variant="secondary"
              model="square"
              hasArrow={false}
            >
              <SettingIcon />
            </DropDown.ButtonTrigger>

            <DropDown.Content orientation="bottom-right">
              {columns?.map((column) => (
                <DropDown.Item key={column.dataKey}>
                  <Checkbox
                    onChange={() => onHandleColumnsShow(column.key)}
                    value={getColumnsShow(column.key)}
                  >
                    {column.title}
                  </Checkbox>
                </DropDown.Item>
              ))}
            </DropDown.Content>
          </DropDown.Root>
        </div>
      </div>

      <TableColumns<QuotationRequestProps>
        isLoading={isLoading || isPending}
        columns={memoizedColumns}
        fixedColumns={[
          {
            title: t('quotationRequestTableActions'),
            key: 'uuid',
            dataKey: 'uuid',
            render: ({ record }) => (
              <div className={styles['action-buttons']}>
                <Button
                  variant="secondary"
                  onClick={() => onHandleEditRegister(record.uuid)}
                >
                  {t('quotationRequestTableActionsEdit')}
                </Button>
                <Button
                  variant="secondary"
                  href={ROUTES_ADMIN.BUSINESS.RFQ.SHOW.fullPath(record.uuid)}
                >
                  {t('quotationRequestTableActionsView')}
                </Button>
                {isSuperUser && (
                  <Button
                    model="square"
                    variant="danger"
                    onClick={() => onHandleDelete(record.uuid)}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>
            ),
            renderLoading: (
              <div className={styles['action-buttons']}>
                <Skeleton height="2rem" width="5.15rem" />
                <Skeleton height="2rem" width="5.7rem" />
                {isSuperUser && <Skeleton height="2rem" width="2rem" />}
              </div>
            ),
          },
        ]}
        data={data?.data?.results || []}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('quotationRequestTableShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', page)}
          currentPage={+filter.page as number}
        />
      </div>

      <QuotationRequestDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />

      <QuotationRequestDrawerForm
        isOpen={isOpenDrawerForm}
        onHandleOpen={onHandleOpenDrawerForm}
        quotationRequestUuid={quotationRequestUuid}
      />
    </div>
  )
}
