export const QUERIES_ADMIN = {
  ADDRESS: {
    module: 'address',
  },
  AUTH: {
    module: 'auth',
  },
  PROFILE: {
    module: 'profile',
  },
  FINANCE_PROFILE: {
    module: 'finance-profile',
  },
  QUOTATION: {
    module: 'quotation',
  },
  QUOTATION_REQUEST: {
    module: 'quotation-request',
  },
  WHO_AM_I: {
    module: 'who-am-i',
  },
  CATALOG_CATEGORY: {
    module: 'catalog-category',
  },
  CATALOG_PRODUCT: {
    module: 'catalog-product',
  },
  FILE: {
    module: 'file',
  },
  ORDER: {
    module: 'order',
  },
  ORDER_ITEM: {
    module: 'order-item',
  },
  USER: {
    module: 'user',
  },
  ACCOUNT_LEVEL_QUESTION: {
    module: 'account-level-question',
  },
  SEARCH_ENGINE: {
    module: 'search-engine',
  },
  SUPPLIER: {
    module: 'supplier',
  },
  SUPPLIER_PRODUCTS: {
    module: 'supplier-products',
  },
  TRANSPORT_COMPANY: {
    module: 'transport-company',
  },
  ROUTE: {
    module: 'routes',
  },
  VEHICLES: {
    module: 'vehicles',
  },
  TRANSPORT_COMPANY_ROUTE: {
    module: 'transport-company-routes',
  },
  ORDER_TRACKER: {
    module: 'order-tracker',
  },
  KAI_PROCESSOR: {
    module: 'kai-processor',
  },
  DOLLAR_QUOTATION: {
    module: 'dollar-quotation',
  },
  ORDER_CENTER: {
    module: 'order-center',
  },
  CREDIT_ORDER: {
    module: 'credit-order',
  },
  TRANSACTION: {
    module: 'transaction',
  },
  PROFILE_USER: {
    module: 'profile-user',
  },
}
