import { FinanceProfilePaymentFormsEnum } from './finance-profile.type'

export enum OrderInvoiceStatusEnum {
  'pending' = 'pending',
  'uploaded' = 'uploaded',
  'cancelled' = 'cancelled',
}

export enum OrderInvoiceOptionsEnum {
  'general_public' = 'general_public',
  'buyer' = 'buyer',
}

export interface OrderInvoiceProps {
  uuid: string
  order: string
  payer: string
  value: string
  currency: string
  option: keyof typeof OrderInvoiceOptionsEnum
  pdfFile: string
  xmlFile: string
  metadata: Record<string, string>
  status: keyof typeof OrderInvoiceStatusEnum
  statusHistory: Record<string, string>
  expiresAt: string
  invoicedAt: string
  updatedAt: string
}

export enum OrderPaymentStatusEnum {
  'pending' = 'pending',
  'waiting_approval' = 'waiting_approval',
  'paid' = 'paid',
  'invalid' = 'invalid',
  'cancelled' = 'cancelled',
}

export enum OrderPaymentCurrencyEnum {
  'USD' = 'USD',
  'MXN' = 'MXN',
}

export interface OrderPaymentProps {
  uuid: string
  order: string
  payer: string
  value: string
  currency: keyof typeof OrderPaymentCurrencyEnum
  paymentLink: string
  proof: string
  metadata: Record<string, string>
  status: keyof typeof OrderPaymentStatusEnum
  statusHistory: Record<string, string>
  statusReason: string
  paidAt: string
  dollarExchangeRate: string
  method: 'credit' | 'bank_transfer' | 'payment_gateway'
  dollarValue: string
  instalment: number
  form: keyof typeof FinanceProfilePaymentFormsEnum
  createdAt: string
  updatedAt: string
}
