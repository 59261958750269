import { useState, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DrawerStepper,
  EditIcon,
  Tooltip,
  Tag,
  Text,
  ChevronLeftIcon,
  ChevronRightIcon,
  AlertIcon,
} from '@mercai/clever'

import {
  onSuccessMessage,
  onErrorMessage,
  isEditOrderTracker,
  isFinisherOrders,
  formatDate,
  parseFormattedDateToDate,
  parseDateTimeToISO,
  isEnabledCreditOrderTracker,
  cleanObject,
  getDifferenceInDays,
} from '@/helpers'

import { useAuth } from '@/providers'

import {
  OrderDateForm,
  OrderForm,
  OrderItemForm,
  OrderProfileList,
  SaleOrderForm,
  CreditOrderForm,
} from '@/components/forms'

import {
  useOrderCenterSaleOrderDetail,
  useOrderCenterSaleOrderUpdate,
  useCreateCreditOrder,
  useUpdateCreditOrder,
} from '@/services'

import { generateClassesOfEllipsesTable } from './order-table-columns.constants'

import styles from './order-table-columns.module.css'

import type {
  OrderTablePODateProps,
  OrderTableProfileProps,
  OrderTableProductDescriptionProps,
  OrderTableProductIndicatorsProps,
  OrderTableOrderIdProps,
  OrderTableSaleOrderStatusProps,
  OrderTableCreditOrderProps,
} from './order-table-columns.type'
import {
  OrderTrackerItemsDataProps,
  ProfileProps,
  OrderProps,
  SaleOrderProps,
  SaleOrderStatusEnum,
  SaleOrderItemProps,
  CreditOrderProps,
  LogisticShipmentProps,
  OrderPaymentProps,
  OrderInvoiceProps,
  OrderTrackerInvoicesStatusEnum,
  OrderTrackerShipmentsStatusEnum,
  OrderTrackerPaymentsStatusEnum,
} from '@/types'
import { ROUTES_ADMIN } from '@/router'

export const OrderTableColumnStatus = ({
  value = 'pending_buyer_data',
}: {
  value?: string
}) => {
  const { t } = useTranslation('enums')

  const stateObject: Record<string, ReactNode> = {
    pending_buyer_data: (
      <Tag variant="warning" model="light">
        {t('order_status_pending_buyer_data')}
      </Tag>
    ),
    pending_invoice: (
      <Tag variant="warning" model="light">
        {t('order_status_pending_invoice')}
      </Tag>
    ),
    pending_payment: (
      <Tag variant="warning" model="light">
        {t('order_status_pending_payment')}
      </Tag>
    ),
    waiting_payment_approval: (
      <Tag variant="warning" model="light">
        {t('order_status_waiting_payment_approval')}
      </Tag>
    ),
    paid: (
      <Tag variant="primary" model="light">
        {t('order_status_paid')}
      </Tag>
    ),
    partial_delivered: (
      <Tag variant="primary" model="light">
        {t('order_status_partial_delivered')}
      </Tag>
    ),
    delivered: (
      <Tag variant="success" model="light">
        {t('order_status_delivered')}
      </Tag>
    ),
    canceled: (
      <Tag variant="danger" model="light">
        {t('order_status_canceled')}
      </Tag>
    ),
    fraudulent: (
      <Tag variant="danger" model="light">
        {t('order_status_fraudulent')}
      </Tag>
    ),
  }

  return stateObject[value] || <Tag variant="warning">{value}</Tag>
}

export const SaleOrderTableColumnStatus = ({
  saleOrderStatus = 'created',
  onSuccessEdit,
  saleOrderUuid,
}: OrderTableSaleOrderStatusProps) => {
  const { t } = useTranslation('screenOrderTracker')
  const { t: tEnums } = useTranslation('enums')

  const stateObject: Record<SaleOrderStatusEnum, ReactNode> = {
    created: (
      <Tag variant="warning" model="light">
        {tEnums('sale_order_status_created')}
      </Tag>
    ),
    processing: (
      <Tag variant="warning" model="light">
        {tEnums('sale_order_status_processing')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('sale_order_status_completed')}
      </Tag>
    ),
    fraudulent: (
      <Tag variant="danger" model="light">
        {tEnums('sale_order_status_fraudulent')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {tEnums('sale_order_status_cancelled')}
      </Tag>
    ),
  }

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [values, setValues] = useState<Partial<SaleOrderProps>>({})

  const { whoAmI } = useAuth()

  const { mutateAsync } = useOrderCenterSaleOrderUpdate(saleOrderUuid)

  useEffect(() => {
    if (saleOrderStatus) {
      setValues({
        status: saleOrderStatus,
      })
    }
  }, [saleOrderStatus, isOpenDrawer])

  const onHandleSubmit = async () => {
    const result = await mutateAsync({
      status: values.status,
    })

    if (result.success) {
      onSuccessMessage(
        t('formSubmitSuccessTitle'),
        t('formSubmitSuccessMessage'),
      )

      setIsOpenDrawer(false)

      onSuccessEdit()
    } else {
      onErrorMessage(t('formSubmitErrorTitle'), t('formSubmitErrorMessage'))
    }
  }

  return (
    <div className={styles['form-field-wrap']}>
      {stateObject[saleOrderStatus] || (
        <Tag variant="warning">{saleOrderStatus}</Tag>
      )}

      {isFinisherOrders(whoAmI?.email) && (
        <>
          <Button
            size="small"
            model="square"
            variant="secondary"
            onClick={() => setIsOpenDrawer(true)}
          >
            <EditIcon />
          </Button>

          <DrawerStepper
            initialStep="root"
            isOpen={isOpenDrawer}
            onHandleOpen={setIsOpenDrawer}
            title={t('orderFormTitle')}
            translations={{
              optional: '',
            }}
            steps={{
              root: {
                backNavigate: 'root',
                component: (
                  <SaleOrderForm
                    onChangeValues={setValues}
                    values={values}
                    onHandleSubmit={onHandleSubmit}
                    onBack={() => setIsOpenDrawer(false)}
                  />
                ),
                isOptional: false,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

export const OrderTableOrderId = ({
  orderId,
  saleOrderUuid,
  onSuccessEdit,
  saleOrderStatus,
}: OrderTableOrderIdProps) => {
  const { t } = useTranslation('screenOrderTracker')

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [values, setValues] = useState<Partial<SaleOrderProps>>({})
  const [shipmentValues, setShipmentValues] = useState<
    Partial<LogisticShipmentProps>
  >({})
  const [paymentValues, setPaymentValues] = useState<
    Partial<OrderPaymentProps>
  >({})
  const { whoAmI } = useAuth()

  const { data: orderDetail } = useOrderCenterSaleOrderDetail(
    isOpenDrawer ? saleOrderUuid : undefined,
  )

  const { mutateAsync } = useOrderCenterSaleOrderUpdate(saleOrderUuid)

  useEffect(() => {
    if (orderDetail?.success && orderDetail?.data) {
      setValues({
        ...orderDetail?.data,
        keyAccountManagerId: orderDetail?.data?.keyAccountManager?.id,
        purchaseOnCredit: +(orderDetail?.data?.financingCost || 0) > 0,
      })

      setShipmentValues(orderDetail?.data?.shipments?.[0])
      setPaymentValues(orderDetail?.data?.payments?.[0])
    }
  }, [orderDetail?.data, isOpenDrawer])

  const payloadShipment = {
    uuid: orderDetail?.data?.shipments?.[0]?.uuid,
    expectedDeliveryAt: shipmentValues.expectedDeliveryAt,
    expectedDeliveryDays: shipmentValues.expectedDeliveryDays,
    deliveredAt: shipmentValues.deliveredAt,
    firstMileCost: shipmentValues.firstMileCost,
    lastMileCost: shipmentValues.lastMileCost,
    customsCost: shipmentValues.customsCost,
    deliveryOption: shipmentValues.deliveryOption,
    price: shipmentValues.price,
  } as LogisticShipmentProps

  const onHandleSubmit = async () => {
    const result = await mutateAsync({
      status: values.status,
      keyAccountManagerId: values.keyAccountManagerId,
      discount: values.discount,
      includeIva: values.includeIva,
      financingCost: values?.purchaseOnCredit ? values.financingCost : '0',
      shipments: [payloadShipment],
    })

    if (result.success) {
      onSuccessMessage(
        t('formSubmitSuccessTitle'),
        t('formSubmitSuccessMessage'),
      )

      setIsOpenDrawer(false)

      onSuccessEdit()
    } else {
      onErrorMessage(t('formSubmitErrorTitle'), t('formSubmitErrorMessage'))
    }
  }

  return (
    <div className={styles['form-field-wrap']}>
      {orderId}
      {isEditOrderTracker(whoAmI?.email) && saleOrderStatus !== 'completed' && (
        <>
          <Button
            size="small"
            model="square"
            variant="secondary"
            onClick={() => setIsOpenDrawer(true)}
          >
            <EditIcon />
          </Button>

          <DrawerStepper
            initialStep="root"
            isOpen={isOpenDrawer}
            onHandleOpen={setIsOpenDrawer}
            title={t('orderFormTitle')}
            translations={{
              optional: '',
            }}
            steps={{
              root: {
                backNavigate: 'root',
                component: (
                  <OrderForm
                    onChangeShipmentValues={setShipmentValues}
                    onChangePaymentValues={setPaymentValues}
                    shipmentValues={shipmentValues}
                    paymentValues={paymentValues}
                    onBack={() => setIsOpenDrawer(false)}
                    onChangeValues={setValues}
                    onHandleSubmit={onHandleSubmit}
                    values={values}
                  />
                ),
                isOptional: false,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

export const OrderTablePODate = ({
  createdAt,
  saleOrderUuid,
  onSuccessEdit,
  saleOrderStatus,
}: OrderTablePODateProps) => {
  const { t } = useTranslation('screenOrderTracker')

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [values, setValues] = useState<Partial<OrderProps>>({})

  const { whoAmI } = useAuth()

  const { data: orderDetail } = useOrderCenterSaleOrderDetail(
    isOpenDrawer ? saleOrderUuid : undefined,
  )

  const { mutateAsync } = useOrderCenterSaleOrderUpdate(saleOrderUuid)

  useEffect(() => {
    if (orderDetail?.success && orderDetail?.data) {
      setValues({
        ...values,
        createdAt: orderDetail?.data.createdAt
          ? formatDate(orderDetail?.data.createdAt)
          : '',
        invoicedAt: orderDetail?.data?.invoices?.[0]?.invoicedAt
          ? formatDate(orderDetail?.data?.invoices?.[0]?.invoicedAt)
          : '',
        paidAt: orderDetail?.data?.payments?.[0]?.paidAt
          ? formatDate(orderDetail?.data?.payments?.[0]?.paidAt)
          : '',
        expectedDeliveryAt: orderDetail?.data?.shipments?.[0]
          ?.expectedDeliveryAt
          ? formatDate(orderDetail?.data?.shipments?.[0]?.expectedDeliveryAt)
          : '',
        deliveredAt: orderDetail?.data?.shipments?.[0]?.deliveredAt
          ? formatDate(orderDetail?.data?.shipments?.[0]?.deliveredAt)
          : '',
      })
    }
  }, [orderDetail?.data, isOpenDrawer])

  const onHandleSubmit = async () => {
    const payload: Record<string, string | null> = {}

    payload.paidAt = values.paidAt
      ? parseFormattedDateToDate(values.paidAt)
      : null
    payload.expectedDeliveryAt = values.expectedDeliveryAt
      ? parseDateTimeToISO(values.expectedDeliveryAt)
      : null
    payload.deliveredAt = values.deliveredAt
      ? parseDateTimeToISO(values.deliveredAt)
      : null

    payload.invoicedAt = values.invoicedAt
      ? parseFormattedDateToDate(values.invoicedAt)
      : null

    const shipment = {
      uuid: orderDetail?.data?.shipments?.[0]?.uuid,
      expectedDeliveryAt: payload.expectedDeliveryAt,
      deliveredAt: payload.deliveredAt,
    } as LogisticShipmentProps

    const payment = {
      uuid: orderDetail?.data?.payments?.[0]?.uuid,
      paidAt: payload.paidAt,
    } as OrderPaymentProps

    const invoice = {
      uuid: orderDetail?.data?.invoices?.[0]?.uuid,
      invoicedAt: payload.invoicedAt,
    } as OrderInvoiceProps

    const result = await mutateAsync({
      createdAt: payload.createdAt as string,
      shipments: [shipment],
      payments: [payment],
      invoices: [invoice],
    })

    if (result.success) {
      onSuccessMessage(
        t('formSubmitSuccessTitle'),
        t('formSubmitSuccessMessage'),
      )

      setIsOpenDrawer(false)

      onSuccessEdit()
    } else {
      onErrorMessage(t('formSubmitErrorTitle'), t('formSubmitErrorMessage'))
    }
  }

  return (
    <div className={styles['form-field-wrap']}>
      {createdAt ? formatDate(createdAt) : ''}
      {isEditOrderTracker(whoAmI?.email) && saleOrderStatus !== 'completed' && (
        <>
          <Button
            size="small"
            model="square"
            variant="secondary"
            onClick={() => setIsOpenDrawer(true)}
          >
            <EditIcon />
          </Button>

          <DrawerStepper
            initialStep="root"
            isOpen={isOpenDrawer}
            onHandleOpen={setIsOpenDrawer}
            title={t('orderFormTitle')}
            translations={{
              optional: '',
            }}
            steps={{
              root: {
                backNavigate: 'root',
                component: (
                  <OrderDateForm
                    onBack={() => setIsOpenDrawer(false)}
                    onChangeValues={setValues}
                    onHandleSubmit={onHandleSubmit}
                    values={values}
                  />
                ),
                isOptional: false,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

export const OrderTableCustomerName = ({
  saleOrderUuid,
  profileName,
  profileUuid,
  onSuccessEdit,
  saleOrderStatus,
}: OrderTableProfileProps) => {
  const { t } = useTranslation('screenOrderTracker')

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [values, setValues] = useState<Partial<ProfileProps> | undefined>(
    undefined,
  )
  const [search, setSearch] = useState('')

  const { whoAmI } = useAuth()

  const { data: orderDetail } = useOrderCenterSaleOrderDetail(
    isOpenDrawer ? saleOrderUuid : undefined,
  )

  const { mutateAsync } = useOrderCenterSaleOrderUpdate(saleOrderUuid)

  useEffect(() => {
    if (orderDetail?.success) {
      setValues(orderDetail?.data?.buyer as unknown as ProfileProps)
      setSearch(profileName || '')
    }
  }, [orderDetail?.data?.buyer, isOpenDrawer])

  const onHandleSubmit = async () => {
    const result = await mutateAsync({
      buyerUuid: values?.uuid,
      shipments: [
        {
          uuid: orderDetail?.data?.shipments?.[0]?.uuid,
          buyerUuid: values?.uuid,
        } as unknown as LogisticShipmentProps,
      ],
      payments: [
        {
          uuid: orderDetail?.data?.payments?.[0]?.uuid,
          payerUuid: values?.uuid,
        } as unknown as OrderPaymentProps,
      ],
      invoices: [
        {
          uuid: orderDetail?.data?.invoices?.[0]?.uuid,
          payerUuid: values?.uuid,
        } as unknown as OrderInvoiceProps,
      ],
    })

    if (result.success) {
      onSuccessMessage(
        t('formSubmitSuccessTitle'),
        t('formSubmitSuccessMessage'),
      )

      setIsOpenDrawer(false)

      onSuccessEdit()
    } else {
      onErrorMessage(t('formSubmitErrorTitle'), t('formSubmitErrorMessage'))
    }
  }

  return (
    <div className={styles['form-field-wrap']}>
      <Text
        href={ROUTES_ADMIN.PROFILE.SHOW.fullPath(profileUuid || '')}
        variant="link"
        className={styles.link}
        target="_blank"
      >
        {profileName}
      </Text>

      {isEditOrderTracker(whoAmI?.email) && saleOrderStatus !== 'completed' && (
        <>
          <Button
            size="small"
            model="square"
            variant="secondary"
            onClick={() => setIsOpenDrawer(true)}
          >
            <EditIcon />
          </Button>

          <DrawerStepper
            initialStep="root"
            isOpen={isOpenDrawer}
            onHandleOpen={setIsOpenDrawer}
            title={t('orderItemFormTitle')}
            translations={{
              optional: '',
            }}
            steps={{
              root: {
                backNavigate: 'root',
                component: (
                  <OrderProfileList
                    onSelectedProfile={setValues}
                    profileSelected={values}
                    search={search}
                    onChangeSearch={setSearch}
                    onHandleSubmit={onHandleSubmit}
                    onClickBack={() => setIsOpenDrawer(false)}
                  />
                ),
                isOptional: false,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

const getSelectedProduct = (
  selectedItems: Record<string, string>,
  orderRefence: string,
  orderItems: OrderTrackerItemsDataProps[],
) => {
  if (selectedItems[orderRefence]) {
    return orderItems.find(
      (item) => selectedItems[orderRefence] === item.saleOrderItemUuid,
    )
  }

  return orderItems?.[0]
}

export const OrderTableProductIndicators = ({
  items,
  selectedItems,
  orderReference,
  onSelectItems,
}: OrderTableProductIndicatorsProps) => {
  const selectedProduct = getSelectedProduct(
    selectedItems,
    orderReference,
    items,
  )

  const getClasses = (item: OrderTrackerItemsDataProps) => {
    return generateClassesOfEllipsesTable(
      selectedProduct?.saleOrderItemUuid === item.saleOrderItemUuid,
    )
  }

  const onClickPrev = () => {
    const selectedProductIndex = items.findIndex(
      (item) => item.saleOrderItemUuid === selectedProduct?.saleOrderItemUuid,
    )

    if (selectedProductIndex === 0) {
      return
    }

    onSelectItems(items[selectedProductIndex - 1].saleOrderItemUuid)
  }

  const onClickNext = () => {
    const selectedProductIndex = items.findIndex(
      (item) => item.saleOrderItemUuid === selectedProduct?.saleOrderItemUuid,
    )

    if (selectedProductIndex === items.length - 1) {
      return
    }

    onSelectItems(items[selectedProductIndex + 1].saleOrderItemUuid)
  }

  return (
    <div className={styles['product-indicators-container']}>
      {items?.length > 1 && (
        <button
          onClick={onClickPrev}
          className={styles['indicators-arrow-button']}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div className={styles['indicators-wrap']}>
        {items.map((item) => (
          <button
            key={item.saleOrderItemUuid}
            className={getClasses(item)}
            onClick={() => onSelectItems(item.saleOrderItemUuid)}
          ></button>
        ))}
      </div>

      {items?.length > 1 && (
        <button
          onClick={onClickNext}
          className={styles['indicators-arrow-button']}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  )
}

export const OrderTableProductDescription = ({
  orderReference,
  items,
  selectedItems,
  onSuccessEditItem,
  saleOrderStatus,
  saleOrderUuid,
}: OrderTableProductDescriptionProps) => {
  const { t } = useTranslation('screenOrderTracker')

  const { whoAmI } = useAuth()

  const [values, setValues] = useState<Partial<SaleOrderItemProps>>({})
  const [shipmentValues, setShipmentValues] = useState<
    Partial<LogisticShipmentProps>
  >({})

  const selectedProduct = getSelectedProduct(
    selectedItems,
    orderReference,
    items,
  )

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const { data: orderDetail } = useOrderCenterSaleOrderDetail(
    isOpenDrawer ? saleOrderUuid : undefined,
  )
  const { mutateAsync } = useOrderCenterSaleOrderUpdate(saleOrderUuid)

  useEffect(() => {
    if (orderDetail?.success) {
      const orderItemDetail = orderDetail?.data?.items?.find(
        (item) => item.uuid === selectedProduct?.saleOrderItemUuid,
      )

      setValues({
        ...orderItemDetail,
        catalogProductUuid: orderItemDetail?.catalogProduct?.uuid,
      } as Partial<SaleOrderItemProps>)

      setShipmentValues({
        ...orderDetail?.data?.shipments?.[0],
        supplierUuid: orderDetail?.data?.shipments?.[0]?.supplier?.uuid,
      })
    }
  }, [orderDetail, isOpenDrawer])

  const onHandleSubmit = async () => {
    const products = orderDetail?.data?.items?.map((item) => {
      const itemObject = item.uuid === values?.uuid ? values : item

      return {
        uuid: item.uuid,
        catalogProductUuid: itemObject.catalogProductUuid,
        nonCatalogProduct: itemObject.nonCatalogProduct,
        cost: itemObject.cost,
        package: itemObject.package,
        price: itemObject.price,
        quantity: itemObject.quantity,
        traderCommission: itemObject?.traderCommission || '0',
      }
    })

    const shipment = orderDetail?.data?.shipments?.[0]

    const result = await mutateAsync({
      items: products as SaleOrderItemProps[],
      shipments: [
        {
          uuid: shipment?.uuid,
          supplierPaidAt: shipmentValues?.supplierPaidAt,
          supplierUuid: shipmentValues?.supplierUuid,
        } as LogisticShipmentProps,
      ],
    })

    if (result.success) {
      onSuccessMessage(
        t('formSubmitSuccessTitle'),
        t('formSubmitSuccessMessage'),
      )

      onSuccessEditItem()
    } else {
      onErrorMessage(t('formSubmitErrorTitle'), t('formSubmitErrorMessage'))
    }
  }

  return (
    <div className={styles['form-field-wrap']}>
      <Tooltip
        text={selectedProduct?.productData.productTitle || ''}
        widthText="12rem"
      >
        <span className={styles['product-description-text']}>
          {selectedProduct?.productData.productTitle}
        </span>
      </Tooltip>
      {isEditOrderTracker(whoAmI?.email) && saleOrderStatus !== 'completed' && (
        <>
          <Button
            size="small"
            model="square"
            variant="secondary"
            onClick={() => setIsOpenDrawer(true)}
          >
            <EditIcon />
          </Button>

          <DrawerStepper
            initialStep="root"
            isOpen={isOpenDrawer}
            onHandleOpen={setIsOpenDrawer}
            title={t('orderFormTitle')}
            translations={{
              optional: '',
            }}
            steps={{
              root: {
                backNavigate: 'root',
                component: (
                  <OrderItemForm
                    isNewRegister={false}
                    onChangeValues={setValues}
                    onClickBack={() => setIsOpenDrawer(false)}
                    onHandleSubmit={onHandleSubmit}
                    values={values}
                    shipmentValues={shipmentValues}
                    onChangeShipmentValues={setShipmentValues}
                  />
                ),
                isOptional: false,
              },
            }}
          />
        </>
      )}
    </div>
  )
}

export const OrderTableCreditOrder = ({
  orderTrackerData,
  onSuccessEdit,
  saleOrderStatus,
}: OrderTableCreditOrderProps) => {
  const { t } = useTranslation('screenOrderTracker')

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [values, setValues] = useState<Partial<CreditOrderProps>>({})

  const { whoAmI } = useAuth()

  const { mutateAsync: mutateAsyncCreate } = useCreateCreditOrder()
  const { mutateAsync: mutateAsyncUpdate } = useUpdateCreditOrder(
    orderTrackerData?.creditData?.creditOrderUuid,
  )

  useEffect(() => {
    if (orderTrackerData?.creditData) {
      setValues({
        amount: orderTrackerData?.creditData?.creditOrderAmount || '0',
        interest: orderTrackerData?.creditData?.interest || '0.02',
        paymentTerm: orderTrackerData?.creditData?.paymentTerm || '30',
        status: orderTrackerData?.creditData?.creditOrderStatus || 'pending',
        expectedPaymentDate:
          orderTrackerData?.creditData?.expectedPaymentDate || '',
        paidAt: orderTrackerData?.creditData?.realPaymentDate || '',
      })
    }
  }, [orderTrackerData?.creditData, isOpenDrawer])

  const isNewRecord = !orderTrackerData?.creditData?.creditOrderUuid

  const onHandleSubmit = async () => {
    const mutateAsync = isNewRecord ? mutateAsyncCreate : mutateAsyncUpdate

    const payload: Partial<CreditOrderProps> = {
      debtorUuid: orderTrackerData?.customerData?.customerUuid || '',
      amount: Number(
        orderTrackerData?.grossProfitData?.orderGmv || '0',
      ).toFixed(2),
      orderUuid: orderTrackerData?.orderData?.saleOrderUuid || '',
      paymentTerm: values?.paymentTerm,
      status: values?.status,
      interest: values?.interest,
      expectedPaymentDate: values?.expectedPaymentDate,
      paidAt: values?.paidAt,
    }

    const result = await mutateAsync(cleanObject(payload))

    if (result.success) {
      onSuccessMessage(
        t('formSubmitSuccessTitle'),
        t('formSubmitSuccessMessage'),
      )

      setIsOpenDrawer(false)
      onSuccessEdit()
    } else {
      onErrorMessage(t('formSubmitErrorTitle'), t('formSubmitErrorMessage'))
    }
  }

  if (
    !isEnabledCreditOrderTracker(whoAmI?.email) &&
    orderTrackerData?.customerFinanceData?.creditStatus === 'approved'
  ) {
    return (
      <div className={styles['text-row']}>
        <Text>{t('tableColumnCreditUserNotHasPermission')}</Text>
        <AlertIcon />
      </div>
    )
  }

  if (orderTrackerData?.customerFinanceData?.creditStatus !== 'approved') {
    return (
      <div className={styles['text-row']}>
        <Text>{t('tableColumnCreditNotPermittedLabel')}</Text>
        <AlertIcon />
      </div>
    )
  }

  return (
    <div className={styles['form-field-wrap']}>
      {orderTrackerData?.creditData?.paymentTerm ? (
        <>
          {orderTrackerData?.creditData?.paymentTerm}{' '}
          {t('tableColumnCreditPaymentTermLabel')}
        </>
      ) : (
        <Text onClick={() => setIsOpenDrawer(true)} className={styles.link}>
          {t('tableColumnCreditNewCreditOrderLabel')}
        </Text>
      )}

      {isEnabledCreditOrderTracker(whoAmI?.email) &&
        saleOrderStatus !== 'completed' && (
          <>
            {orderTrackerData?.creditData?.paymentTerm && (
              <Button
                size="small"
                model="square"
                variant="secondary"
                onClick={() => setIsOpenDrawer(true)}
              >
                <EditIcon />
              </Button>
            )}

            <DrawerStepper
              initialStep="root"
              isOpen={isOpenDrawer}
              onHandleOpen={setIsOpenDrawer}
              title={t('creditOrderFormTitle')}
              translations={{
                optional: '',
              }}
              steps={{
                root: {
                  backNavigate: 'root',
                  component: (
                    <CreditOrderForm
                      onChangeValues={setValues}
                      values={values}
                      onHandleSubmit={onHandleSubmit}
                      onBack={() => setIsOpenDrawer(false)}
                    />
                  ),
                  isOptional: false,
                },
              }}
            />
          </>
        )}
    </div>
  )
}

export const OrderTableColumnCreditOrderStatus = ({
  value,
}: {
  value?: string
}) => {
  const { t } = useTranslation('enums')

  if (!value) {
    return '-'
  }

  const stateObject: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('credit_order_status_pending')}
      </Tag>
    ),
    active: (
      <Tag variant="primary" model="light">
        {t('credit_order_status_active')}
      </Tag>
    ),
    denied: (
      <Tag variant="danger" model="light">
        {t('credit_order_status_denied')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light">
        {t('credit_order_status_paid')}
      </Tag>
    ),
    payment_overdue: (
      <Tag variant="danger" model="light">
        {t('credit_order_status_payment_overdue')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('credit_order_status_cancelled')}
      </Tag>
    ),
  }

  return stateObject[value] || <Tag variant="warning">{value}</Tag>
}

export const OrderTableColumnInternalCreditDaysBetweenDates = ({
  expectedPaymentDate,
  realPaymentDate,
}: {
  expectedPaymentDate?: string
  realPaymentDate?: string
}) => {
  const { t } = useTranslation('screenOrderTracker')

  const days = getDifferenceInDays(expectedPaymentDate, realPaymentDate)

  if (!realPaymentDate) {
    return '-'
  }

  return (
    <Text className={days > 0 ? styles['error-text'] : ''}>
      {`${days} ${t('tableInternalCreditDifferencePaymentDateUnitLabel')}`}
    </Text>
  )
}

export const OrderTableColumnInvoicesStatus = ({
  value = 'processing',
}: {
  value?: keyof typeof OrderTrackerInvoicesStatusEnum
}) => {
  const { t } = useTranslation('enums')

  const stateObject: Record<OrderTrackerInvoicesStatusEnum, ReactNode> = {
    processing: (
      <Tag variant="warning" model="light">
        {t('order_tracker_invoices_status_processing')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('order_tracker_invoices_status_cancelled')}
      </Tag>
    ),
    expired: (
      <Tag variant="danger" model="light">
        {t('order_tracker_invoices_status_expired')}
      </Tag>
    ),
    invoiced: (
      <Tag variant="success" model="light">
        {t('order_tracker_invoices_status_invoiced')}
      </Tag>
    ),
    partially_invoiced: (
      <Tag variant="warning" model="light">
        {t('order_tracker_invoices_status_partially_invoiced')}
      </Tag>
    ),
  }

  return stateObject[value] || <Tag variant="warning">{value}</Tag>
}

export const OrderTableColumnPaymentsStatus = ({
  value = 'processing',
}: {
  value?: keyof typeof OrderTrackerPaymentsStatusEnum
}) => {
  const { t } = useTranslation('enums')

  const stateObject: Record<OrderTrackerPaymentsStatusEnum, ReactNode> = {
    processing: (
      <Tag variant="warning" model="light">
        {t('order_tracker_payments_status_processing')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('order_tracker_payments_status_cancelled')}
      </Tag>
    ),
    invalid: (
      <Tag variant="danger" model="light">
        {t('order_tracker_payments_status_invalid')}
      </Tag>
    ),
    expired: (
      <Tag variant="danger" model="light">
        {t('order_tracker_payments_status_expired')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light">
        {t('order_tracker_payments_status_paid')}
      </Tag>
    ),
    partially_paid: (
      <Tag variant="warning" model="light">
        {t('order_tracker_payments_status_partially_paid')}
      </Tag>
    ),
  }

  return stateObject[value] || <Tag variant="warning">{value}</Tag>
}

export const OrderTableColumnShipmentsStatus = ({
  value = 'processing',
}: {
  value?: keyof typeof OrderTrackerShipmentsStatusEnum
}) => {
  const { t } = useTranslation('enums')

  const stateObject: Record<OrderTrackerShipmentsStatusEnum, ReactNode> = {
    processing: (
      <Tag variant="warning" model="light">
        {t('order_tracker_shipments_status_processing')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('order_tracker_shipments_status_cancelled')}
      </Tag>
    ),
    refused: (
      <Tag variant="danger" model="light">
        {t('order_tracker_shipments_status_refused')}
      </Tag>
    ),
    delivered: (
      <Tag variant="success" model="light">
        {t('order_tracker_shipments_status_delivered')}
      </Tag>
    ),
    partially_delivered: (
      <Tag variant="warning" model="light">
        {t('order_tracker_shipments_status_partially_delivered')}
      </Tag>
    ),
  }

  return stateObject[value] || <Tag variant="warning">{value}</Tag>
}
