import { useEffect, useState, ReactNode } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Drawer } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { onErrorMessage, onSuccessMessage } from '@/helpers'
import {
  useOrderCenterSaleOrderDetail,
  useOrderCenterSaleOrderUpdate,
  QUERIES_ADMIN,
} from '@/services'

import { OrderHeader, FinanceForm, LogisticForm } from './components'
import { fields } from './order-center.constants'

import styles from './order-center-form.module.css'

import type {
  SaleOrderProps,
  OrderInvoiceProps,
  OrderPaymentProps,
  LogisticShipmentProps,
} from '@/types'
import type { OrderCenterFormProps } from './order-center-form.type'

export const OrderCenterForm = ({
  isOpen,
  onHandleOpen,
  saleOrderUuid,
  type = 'profile',
}: OrderCenterFormProps) => {
  const { t } = useTranslation('componentOrderCenterForm')
  const queryClient = useQueryClient()

  const [values, setValues] = useState<SaleOrderProps>({} as SaleOrderProps)

  const [initEditData, setInitEditData] = useState(false)

  const onChangeValues = (newValue: SaleOrderProps) => {
    if (!initEditData) {
      setInitEditData(true)
    }

    setValues((prevValues) => ({
      ...prevValues,
      ...newValue,
    }))
  }

  const { data: order, isLoading } =
    useOrderCenterSaleOrderDetail(saleOrderUuid)

  const { mutateAsync, isPending } =
    useOrderCenterSaleOrderUpdate(saleOrderUuid)

  const onRefetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.ORDER_CENTER.module],
    })
  }

  const components: Record<string, ReactNode> = {
    'order-finance': (
      <FinanceForm
        values={values}
        onChange={onChangeValues}
        onSuccessUploadFiles={onRefetchData}
      />
    ),
    'order-logistic': (
      <LogisticForm
        values={values}
        onChange={onChangeValues}
        onRefetch={onRefetchData}
      />
    ),
  }

  const onHandleSubmit = async () => {
    values.invoices = values.invoices.map(
      (invoice) =>
        ({
          uuid: invoice.uuid,
          status: invoice.status,
        }) as OrderInvoiceProps,
    )
    values.payments = values.payments.map(
      (payment) =>
        ({
          uuid: payment.uuid,
          status: payment.status,
          paidAt: payment.paidAt,
        }) as OrderPaymentProps,
    )

    values.shipments = values.shipments.map(
      (shipment) =>
        ({
          uuid: shipment.uuid,
          status: shipment.status,
          deliveredAt: shipment.deliveredAt,
          transportOptions: shipment.transportOptions || undefined,
          price: shipment.price || undefined,
          customsCost: shipment.customsCost || undefined,
          extraCost: shipment.extraCost || undefined,
          extraCostReason: shipment.extraCostReason || undefined,
          firstMileCost: shipment.firstMileCost || undefined,
          lastMileCost: shipment.lastMileCost || undefined,
          expectedDeliveryAt: shipment.expectedDeliveryAt || undefined,
          expectedDeliveryDays: shipment.expectedDeliveryDays || undefined,
          expectedDeliveryDate: shipment.expectedDeliveryDate || undefined,
        }) as LogisticShipmentProps,
    )

    const response = await mutateAsync({
      invoices: values.invoices,
      payments: values.payments,
      shipments: values.shipments,
    })

    if (response.success) {
      onSuccessMessage(t('formSuccessTitle'), t('formSuccessUpdateMessage'))

      onRefetchData()
      onHandleOpen(false)
    } else {
      onErrorMessage(t('formErrorTitle'), t('formErrorUpdateMessage'))
    }
  }

  useEffect(() => {
    if (isOpen && order?.data && !initEditData) {
      setValues(order?.data)
    }

    if (!isOpen) {
      setValues({} as SaleOrderProps)
      setInitEditData(false)
    }
  }, [order?.data, isOpen])

  return (
    <Drawer
      isOpen={isOpen}
      onHandleOpen={() => onHandleOpen(false)}
      className={styles.drawer}
    >
      <OrderHeader
        reference={order?.data?.reference || ''}
        createdAt={order?.data?.createdAt || ''}
        updatedAt={order?.data?.updatedAt || ''}
        onHandleOpen={onHandleOpen}
        isLoading={isLoading}
      />

      <div className={styles.content}>
        {fields[type]?.map((componentKey) => (
          <div key={componentKey}>
            {components?.[componentKey]}

            <div key={`divisor_${componentKey}`} className={styles.divisor} />
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('buttonClose')}
        </Button>

        <Button onClick={onHandleSubmit} disabled={isPending}>
          {t('buttonSubmit')}
        </Button>
      </div>
    </Drawer>
  )
}
