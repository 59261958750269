'use client'
import { useState } from 'react'
import { Link, useLocation, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  BriefcaseIcon,
  BurguerIcon,
  CloseIcon,
  SupermarketCartIcon,
  Text,
  MegafoneIcon,
  ReceiptIcon,
  PackageIcon,
  TruckIcon,
  ChartIcon,
  SparksIcon,
  ProfileIcon,
  MonetizationIcon,
  TargetIcon,
  ForkLiftIcon,
} from '@mercai/clever'

import { ROUTES_ADMIN } from '@/router'

import styles from './admin-panel.module.css'

export const AdminPanelLayout = () => {
  const { t } = useTranslation('layoutPanelAdmin')

  const location = useLocation()

  const [isOpenResponsiveMenu, setIsOpenResponsiveMenu] = useState(false)

  const getActiveClass = (link: string) => {
    return location?.pathname === `/${link}`
      ? styles['aside-menu-links-active']
      : ''
  }

  return (
    <div className={styles.layout}>
      <main className={styles.main}>
        <Outlet />
      </main>

      <aside className={styles.aside}>
        <header className={styles['aside-header']}>
          <Link to={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()}>
            <img
              src="/images/logo-opus-white.svg"
              alt="Mercai"
              height={32}
              width={112}
            />
          </Link>

          <Button
            variant="ghost"
            className={styles['aside-header-burguer']}
            type="button"
            onClick={() => setIsOpenResponsiveMenu(true)}
          >
            <BurguerIcon />
          </Button>
        </header>

        <button
          className={styles['aside-responsive-overlay']}
          onClick={() => setIsOpenResponsiveMenu(false)}
          style={
            isOpenResponsiveMenu ? { display: 'block' } : { display: 'none' }
          }
        ></button>

        <div
          className={styles['aside-responsive-drawer']}
          style={isOpenResponsiveMenu ? { right: 0 } : {}}
        >
          <main className={styles['aside-main']}>
            <div className={styles['aside-main-responsive-header']}>
              <span>Menu</span>

              <button
                type="button"
                onClick={() => setIsOpenResponsiveMenu(false)}
              >
                <CloseIcon />
              </button>
            </div>

            <Text className={styles['aside-menu-title']}>
              {t('performanceMenus')}
            </Text>

            <ul className={`${styles['aside-menus']}`}>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ANALYTICS.DASHBOARD.path,
                  )}`}
                  to={ROUTES_ADMIN.ANALYTICS.DASHBOARD.fullPath()}
                >
                  <ChartIcon />
                  {t('listItemDashboard')}
                </Link>
              </li>

              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.path,
                  )}`}
                  to={ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.fullPath()}
                >
                  <ChartIcon />
                  {t('listItemOrderTracker')}
                </Link>
              </li>
            </ul>

            <Text className={styles['aside-menu-title']}>
              {t('supplyMenus')}
            </Text>

            <ul className={`${styles['aside-menus']}`}>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.SUPPLY.SUPPLIERS.path,
                  )}`}
                  to={ROUTES_ADMIN.SUPPLY.SUPPLIERS.fullPath()}
                >
                  <ForkLiftIcon />
                  {t('listItemSuppliers')}
                </Link>
              </li>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.path,
                  )}`}
                  to={ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.fullPath()}
                >
                  <TruckIcon />
                  {t('listItemTransportCompanies')}
                </Link>
              </li>
            </ul>

            <Text className={styles['aside-menu-title']}>
              {t('profileMenus')}
            </Text>

            <ul className={`${styles['aside-menus']}`}>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.PROFILE.path,
                  )}`}
                  to={ROUTES_ADMIN.PROFILE.fullPath()}
                >
                  <BriefcaseIcon />
                  {t('listItemLeads')}
                </Link>
              </li>
            </ul>

            <Text className={styles['aside-menu-title']}>
              {t('catalogMenus')}
            </Text>

            <ul className={`${styles['aside-menus']}`}>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.CATALOG.PRODUCT.path,
                  )}`}
                  to={ROUTES_ADMIN.CATALOG.PRODUCT.fullPath()}
                >
                  <SupermarketCartIcon />
                  {t('listItemProducts')}
                </Link>
              </li>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.CATALOG.PROCESSOR.path,
                  )}`}
                  to={ROUTES_ADMIN.CATALOG.PROCESSOR.fullPath()}
                >
                  <SparksIcon />
                  {t('listItemProcessor')}
                </Link>
              </li>
            </ul>

            <Text className={styles['aside-menu-title']}>{t('sellMenus')}</Text>

            <ul className={`${styles['aside-menus']}`}>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.BUSINESS.RFQ.path,
                  )}`}
                  to={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()}
                >
                  <MegafoneIcon />
                  {t('listItemRFQs')}
                </Link>
              </li>

              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.BUSINESS.QUOTATION.path,
                  )}`}
                  to={ROUTES_ADMIN.BUSINESS.QUOTATION.fullPath()}
                >
                  <ReceiptIcon />
                  {t('listItemQuotations')}
                </Link>
              </li>

              {/* <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.BUSINESS.ORDER.path,
                  )}`}
                  to={ROUTES_ADMIN.BUSINESS.ORDER.fullPath()}
                >
                  <PackageIcon />
                  {t('listItemOrders')}
                </Link>
              </li> */}
            </ul>

            <Text className={styles['aside-menu-title']}>
              {t('orderCenterMenus')}
            </Text>

            <ul className={`${styles['aside-menus']}`}>
              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ORDER_CENTER.ALL.path,
                  )}`}
                  to={ROUTES_ADMIN.ORDER_CENTER.ALL.fullPath()}
                >
                  <PackageIcon />
                  {t('listItemOrderCenterAll')}
                </Link>
              </li>

              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ORDER_CENTER.SALE.path,
                  )}`}
                  to={ROUTES_ADMIN.ORDER_CENTER.SALE.fullPath()}
                >
                  <TargetIcon />
                  {t('listItemOrderCenterSale')}
                </Link>
              </li>

              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ORDER_CENTER.PROFILE.path,
                  )}`}
                  to={ROUTES_ADMIN.ORDER_CENTER.PROFILE.fullPath()}
                >
                  <ProfileIcon />
                  {t('listItemOrderCenterProfile')}
                </Link>
              </li>

              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ORDER_CENTER.FINANCE.path,
                  )}`}
                  to={ROUTES_ADMIN.ORDER_CENTER.FINANCE.fullPath()}
                >
                  <MonetizationIcon />
                  {t('listItemOrderCenterFinance')}
                </Link>
              </li>

              <li>
                <Link
                  className={`${styles['aside-menu-links']} ${getActiveClass(
                    ROUTES_ADMIN.ORDER_CENTER.LOGISTIC.path,
                  )}`}
                  to={ROUTES_ADMIN.ORDER_CENTER.LOGISTIC.fullPath()}
                >
                  <TruckIcon />
                  {t('listItemOrderCenterLogistic')}
                </Link>
              </li>
            </ul>
          </main>
        </div>
      </aside>
    </div>
  )
}
