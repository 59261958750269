import { useEffect, useState } from 'react'
import { Card, Tab, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { format, subDays, addDays, differenceInDays } from 'date-fns'

import {
  useOrderCenterFinanceClassification,
  useOrderCenterLogisticClassification,
} from '@/services'

import { Metrics, PeriodDate } from './components'

import { OrderCenterMetricsProps } from './metrics.type'

import styles from './metrics.module.css'

export const OrderCenterMetrics = ({
  type,
  filter,
  onFilterChange,
}: OrderCenterMetricsProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (
      filter?.shipmentExpectedDeliveryAtAfter &&
      filter?.shipmentExpectedDeliveryAtBefore &&
      !selectedTab
    ) {
      const shipmentExpectedDeliveryAtBefore = new Date(
        filter?.shipmentExpectedDeliveryAtBefore,
      )

      const shipmentExpectedDeliveryAtAfter = new Date(
        filter?.shipmentExpectedDeliveryAtAfter,
      )

      const dateNow = new Date()
      dateNow.setHours(23, 59, 59, 999)

      const differenceDates = differenceInDays(
        shipmentExpectedDeliveryAtBefore,
        shipmentExpectedDeliveryAtAfter,
      )

      console.log(differenceDates)

      let days: Record<number, string> = {
        7: 'next7Days',
        14: 'next14Days',
        30: 'next30Days',
        0: 'today',
      }

      if (dateNow.getTime() > shipmentExpectedDeliveryAtAfter.getTime()) {
        days = {
          7: 'last7Days',
          0: 'today',
        }
      }

      setSelectedTab(days?.[Math.abs(differenceDates)] || 'period')
    }
  }, [
    filter?.shipmentExpectedDeliveryAtAfter,
    filter?.shipmentExpectedDeliveryAtBefore,
  ])

  const { data: logisticData } = useOrderCenterLogisticClassification(
    {
      orderCreatedAtAfter: filter?.shipmentExpectedDeliveryAtAfter,
      orderCreatedAtBefore: filter?.shipmentExpectedDeliveryAtBefore,
    },
    type === 'logistic',
  )

  const { data: financeData } = useOrderCenterFinanceClassification(
    {
      orderCreatedAtAfter: filter?.shipmentExpectedDeliveryAtAfter,
      orderCreatedAtBefore: filter?.shipmentExpectedDeliveryAtBefore,
    },
    type === 'finance',
  )

  const onChangeSelectedTab = (tab: string) => {
    setSelectedTab(tab)

    if (
      ['last7Days', 'next14Days', 'next30Days', 'today', 'next7Days'].includes(
        tab,
      )
    ) {
      let selectedDate = new Date()
      const dateNow = new Date()

      let shipmentExpectedDeliveryAtAfter = ''
      let shipmentExpectedDeliveryAtBefore = ''

      const days: Record<string, number> = {
        today: 0,
        next7Days: 7,
        next30Days: 30,
        next14Days: 14,
        last7Days: 7,
      }
      if (tab.includes('last')) {
        selectedDate = subDays(dateNow, days[tab])

        selectedDate.setHours(0, 0, 0, 0)

        dateNow.setHours(23, 59, 59, 999)

        shipmentExpectedDeliveryAtAfter = format(
          selectedDate,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
        )

        shipmentExpectedDeliveryAtBefore = format(
          dateNow,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
        )
      } else {
        selectedDate = addDays(dateNow, days[tab])

        selectedDate.setHours(23, 59, 59, 999)

        dateNow.setHours(0, 0, 0, 0)

        shipmentExpectedDeliveryAtAfter = format(
          dateNow,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
        )

        shipmentExpectedDeliveryAtBefore = format(
          selectedDate,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
        )
      }

      onFilterChange({
        ...filter,
        shipmentExpectedDeliveryAtBefore,
        shipmentExpectedDeliveryAtAfter,
      })
    } else {
      onFilterChange({
        ...filter,
        shipmentExpectedDeliveryAtBefore: '',
        shipmentExpectedDeliveryAtAfter: '',
      })
    }
  }

  const onChangePeriod = (startDate?: string, endDate?: string) => {
    onFilterChange({
      ...filter,
      shipmentExpectedDeliveryAtAfter: startDate || '',
      shipmentExpectedDeliveryAtBefore: endDate || '',
    })
  }

  const onChangeStatusFilter = (status: string) => {
    onFilterChange({
      ...filter,
      classification_group:
        filter?.classification_group === status ? '' : status,
    })
  }

  const columnsMetrics = {
    logistic: [
      {
        label: t('metricsPendingMetric'),
        value: logisticData?.data?.pending ?? 0,
        total: logisticData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'pending',
        onClickFilter: () => onChangeStatusFilter('pending'),
      },
      {
        label: t('metricsOverdueMetric'),
        value: logisticData?.data?.overdue ?? 0,
        total: logisticData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'overdue',
        onClickFilter: () => onChangeStatusFilter('overdue'),
      },
      {
        label: t('metricsInTransitMetric'),
        value: logisticData?.data?.inTransit ?? 0,
        total: logisticData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'in_transit',
        onClickFilter: () => onChangeStatusFilter('in_transit'),
      },
      {
        label: t('metricsDeliveredOnTimeMetric'),
        value: logisticData?.data?.deliveredOnTime ?? 0,
        total: logisticData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'delivered_on_time',
        onClickFilter: () => onChangeStatusFilter('delivered_on_time'),
      },
      {
        label: t('metricsDeliveredLateMetric'),
        value: logisticData?.data?.deliveredLate ?? 0,
        total: logisticData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'delivered_late',
        onClickFilter: () => onChangeStatusFilter('delivered_late'),
      },
      {
        label: t('metricsCanceledMetric'),
        value: logisticData?.data?.cancelled ?? 0,
        total: logisticData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'cancelled',
        onClickFilter: () => onChangeStatusFilter('cancelled'),
      },
      {
        label: t('metricsAvgDeliveryTimeMetric'),
        value: logisticData?.data?.avgDeliveryDays ?? 0,
      },
    ],
    finance: [
      {
        label: t('metricsFinancePendingInvoice'),
        value: financeData?.data?.pendingInvoices ?? 0,
        total: financeData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'pending_invoices',
        onClickFilter: () => onChangeStatusFilter('pending_invoices'),
      },
      {
        label: t('metricsFinancePaymentsInApproval'),
        value: financeData?.data?.paymentsInApproval ?? 0,
        total: financeData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'payments_in_approval',
        onClickFilter: () => onChangeStatusFilter('payments_in_approval'),
      },
      {
        label: t('metricsFinanceDeliveredThisWeek'),
        value: financeData?.data?.deliveredThisWeek ?? 0,
        total: financeData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter: filter.classification_group === 'delivered_this_week',
        onClickFilter: () => onChangeStatusFilter('delivered_this_week'),
      },
      {
        label: t('metricsFinanceDeliveredWithoutPayment'),
        value: financeData?.data?.deliveredWithoutPayment ?? 0,
        total: financeData?.data?.total ?? 0,
        decimalPlaces: 0,
        isActiveFilter:
          filter.classification_group === 'delivered_without_payment',
        onClickFilter: () => onChangeStatusFilter('delivered_without_payment'),
      },
      {
        label: t('metricsFinanceTransactionsWithoutOrder'),
        value: financeData?.data?.transactionsWithoutOrder ?? 0,
        decimalPlaces: 0,
      },
    ],
  }

  const totals = {
    logistic: logisticData?.data?.total ?? 0,
    finance: financeData?.data?.total ?? 0,
  }

  if (['logistic', 'finance'].includes(type)) {
    return (
      <div className={styles.core}>
        <Text variant="title-large">{t('metricsTitle')}</Text>

        <Card className={styles.card}>
          <div className={styles['tab-container']}>
            <Tab
              value={selectedTab || 'all'}
              onChange={onChangeSelectedTab}
              items={[
                {
                  label: t('metricsTabAll'),
                  value: 'all',
                },
                {
                  label: t('metricsTabLast7Days'),
                  value: 'last7Days',
                },
                {
                  label: t('metricsTabToday'),
                  value: 'today',
                },
                {
                  label: t('metricsTabNext7Days'),
                  value: 'next7Days',
                },
                {
                  label: t('metricsTabNext14Days'),
                  value: 'next14Days',
                },
                {
                  label: t('metricsTabNext30Days'),
                  value: 'next30Days',
                },
                {
                  label: t('metricsTabPeriod'),
                  value: 'period',
                },
              ]}
            />

            {selectedTab === 'period' && (
              <PeriodDate
                onPeriodApply={onChangePeriod}
                initialEndDate={filter?.shipmentExpectedDeliveryAtBefore}
                initialStartDate={filter?.shipmentExpectedDeliveryAtAfter}
              />
            )}

            <Text className={styles['total-metric']}>
              {totals[type as 'logistic' | 'finance']} {t('metricsTotalMetric')}
            </Text>
          </div>

          <Metrics metrics={columnsMetrics?.[type as 'logistic' | 'finance']} />
        </Card>
      </div>
    )
  }

  return null
}
