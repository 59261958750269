export const expandedColumns: Record<string, string[]> = {
  logistic: [
    'payment-condition',
    'expected-delivery-date',
    'delivery-at',
    'shipment-origin',
    'shipment-destination',
    'total-freight-price',
    'amount',
    'key-account-manager',
  ],
  finance: [
    'payment-method',
    'payment-condition',
    'total-product-price',
    'total-freight-price',
    'tax-price',
    'total-payment',
    'expected-delivery-date',
    'shipment-destination',
    'delivery-at',
    'key-account-manager',
  ],
}
